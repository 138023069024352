const $ = require('jquery');
require('jquery-validation');

$(document).ready(function () {

    function scrollPageTo (to, duration=500, offset=0) {
        //t = current time
        //b = start value
        //c = change in value
        //d = duration
        const easeInOutQuad = function (t, b, c, d) {
            t /= d/2;
            if (t < 1) return c/2*t*t + b;
            t--;
            return -c/2 * (t*(t-2) - 1) + b;
        };

        return new Promise((resolve, reject) => {
            const element = document.scrollingElement;

            if (typeof to === 'string') {
                to = document.querySelector(to) || reject();
            }
            if (typeof to !== 'number') {
                to = to.getBoundingClientRect().top + element.scrollTop;
            }

            let start = element.scrollTop,
                change = to - start + offset,
                currentTime = 0,
                increment = 20;

            const animateScroll = function() {
                currentTime += increment;
                let val = easeInOutQuad(currentTime, start, change, duration);
                element.scrollTop = val;
                if(currentTime < duration) {
                    setTimeout(animateScroll, increment);
                } else {
                    resolve();
                }
            };
            animateScroll();
        });
    }

    (function () {
        let defaultValidationOptions = {
            onkeyup: function(element) {
                $(element).valid();
            },
            highlight: function(element) {
                $(element).parent('div').removeClass('element-success');
                $(element).parent('div').addClass('element-error');
            },
            unhighlight: function(element) {
                $(element).parent('div').removeClass('element-error');
                $(element).parent('div').addClass('element-success');
            },
            errorPlacement: function(error, element) {
                if (element.attr("type") === "checkbox") {
                    error.appendTo($(element).parent());
                } else {
                    error.insertAfter(element);
                }
            },
            errorElement: "span",
            // Make sure the form is submitted to the destination defined
            // in the "action" attribute of the form when valid
            submitHandler: function(form) {
                form.submit();
            }
        };

        //-- Register reference number
        let referenceNumberRegisterFrom = $("form[name='reference_number_register']");
        if(referenceNumberRegisterFrom.hasClass('form-has-errors')) {
            scrollPageTo(document.getElementById('register-reference-number-wrapper'), 1200, -80);
        }
        referenceNumberRegisterFrom.validate($.extend(defaultValidationOptions, {
            // Specify validation rules
            rules: {
                'reference_number_register[referenceNumber]': "required",
                'reference_number_register[pensionNumber]': "required",
            },
            // Specify validation error messages
            messages: {
                'reference_number_register[referenceNumber]': "Please enter your unique code",
                'reference_number_register[pensionNumber]': "Please enter your member reference",
            }
        }));

        //-- Login form
        let loginForm = $("form[name='login_form']");
        if(loginForm.hasClass('form-has-errors')) {
            scrollPageTo(document.getElementById('login-form-wrapper'), 1200, -80);
        }
        loginForm.validate($.extend(defaultValidationOptions, {
            // Specify validation rules
            rules: {
                email: {
                    required: true,
                    customEmail: true,
                    // email: false
                },
                password: {
                    required: true
                }
            },
            // Specify validation error messages
            messages: {
                customEmail: "Please enter a valid email address",
                email: "Please enter a valid email address",
                password: {
                    required: "Please provide a password"
                },
            }
        }));

        //-- Registration form
        $("form[name='registration_form']").validate($.extend(defaultValidationOptions, {
            rules: {
                email: {
                    required: true,
                    customEmail: true,
                    // email: false
                },
                'registration_form[plainPassword][first]': {
                    required: false,
                    complexPassword: true
                },
                'registration_form[plainPassword][second]': {
                    required: true,
                    equalTo: "input[name='registration_form[plainPassword][first]']"
                },
                'registration_form[termsAccepted]': {
                    required: true
                }
            },
            messages: {
                customEmail: "Please enter a valid email address",
                // email: "Please enter a valid email address",
                'registration_form[plainPassword][first]': {
                    // required: "Please provide a password",
                },
                'registration_form[plainPassword][second]': {
                    equalTo: 'Please enter the same password again'
                },
                'registration_form[termsAccepted]': {
                    required: "Please accept terms",
                }
            },
        }));

        //--- Password reset request
        let passwordResetRequest = $("form[name='password_reset_request']");
        if(passwordResetRequest.hasClass('form-has-errors')) {
            scrollPageTo(document.getElementById('password-reset-request-submit-wrapper'), 1200, -80);
        }
        passwordResetRequest.validate($.extend(defaultValidationOptions, {
            rules: {
                email: {
                    required: true,
                    customEmail: true
                    // email: true
                }
            },
            messages: {
                customEmail: "Please enter a valid email address",
                email: "Please enter a valid email address",
            }
        }));

        //------ Password reset
        $("form[name='password_reset']").validate($.extend(defaultValidationOptions, {
            rules: {
                'password_reset[plainPassword][first]': {
                    required: false,
                    complexPassword: true
                },
                'password_reset[plainPassword][second]': {
                    required: true,
                    equalTo: "input[name='password_reset[plainPassword][first]']"
                },
            },
            messages: {
                // 'password_reset[plainPassword][first]': {
                //     required: "Please provide a password",
                // },
                'password_reset[plainPassword][second]': {
                    equalTo: 'Please enter the same password again'
                },
            }
        }));

        $.validator.addMethod("customEmail", function(value) {
          return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
        });

        //------- Add validator method for password ------//
        $.validator.addMethod("complexPassword", function(value, element) {
            let message = null;
            let validBig = /[A-Z]/.test(value);

            if(validBig) {
                $('.pass-strength-big').addClass('checked');
            } else {
                $('.pass-strength-big').removeClass('checked');
                message = 'Your password needs to contain at least one uppercase letter';
            }

            let validSmall =  /[a-z]/.test(value);
            if(validSmall) {
                $('.pass-strength-small').addClass('checked');
            } else {
                $('.pass-strength-small').removeClass('checked');
                message = 'Your password needs to contain at least one lowercase letter';
            }

            let validNumber =  /\d/.test(value);
            if(validNumber) {
                $('.pass-strength-number').addClass('checked');
            } else {
                $('.pass-strength-number').removeClass('checked');
                message = 'Your password needs to contain at least one number';
            }


            let validSpecial =  /(?=.[!@#\$%\^&])/.test(value);
            if(validSpecial) {
                $('.pass-strength-special').addClass('checked');
            } else {
                $('.pass-strength-special').removeClass('checked');
                message = 'Your password needs to contain at least one special character';
            }

            let validLength =  /(?=.{8,})/.test(value);
            if(validLength) {
                $('.pass-strength-length').addClass('checked');
            } else {
                $('.pass-strength-length').removeClass('checked');
                message = 'Your password needs to contain minimum of 8 characters';
            }

            let validRequired = value !== '';
            if(!validRequired) {
                $('.pass-strength-big').removeClass('checked');
                $('.pass-strength-small').removeClass('checked');
                $('.pass-strength-number').removeClass('checked');
                $('.pass-strength-special').removeClass('checked');
                $('.pass-strength-length').removeClass('checked');
                message = 'Please provide a password';
            }
            
            $(element).attr('data-message', message);
            // this.settings.messages['registration_form[plainPassword][first]']['complexPassword'] = message;
            return validBig && validSmall && validNumber && validSpecial && validLength && validRequired;
        }, function(value, element) {
            return $(element).attr('data-message');
        });

    }());
});
